import type { TFunction } from 'i18next';
export const ACCOUNT_REGISTER_ALLOWED_DOCUMENT_TYPE = {
  RG: 'RG',
  CNH: 'CNH',
} as const;
export type ACCOUNT_REGISTER_ALLOWED_DOCUMENT_TYPE =
  (typeof ACCOUNT_REGISTER_ALLOWED_DOCUMENT_TYPE)[keyof typeof ACCOUNT_REGISTER_ALLOWED_DOCUMENT_TYPE];
export const ACCOUNT_REGISTER_DOCUMENT_TYPE = {
  PICTURE: 'PICTURE',
  CNH: 'CNH',
  CNH_FRONT: 'CNH_FRONT',
  CNH_BACK: 'CNH_BACK',
  IDENTITY_FRONT: 'IDENTITY_FRONT',
  IDENTITY_BACK: 'IDENTITY_BACK',
  SOCIAL_CONTRACT: 'SOCIAL_CONTRACT',
  ADDRESS_PROOF: 'ADDRESS_PROOF',
  ATA: 'ATA',
  BYLAWS: 'BYLAWS',
  BILLING_PROOF: 'BILLING_PROOF',
  SIGNATURE_DOCUMENT: 'SIGNATURE_DOCUMENT',
  CONTRACT_DOCUMENT: 'CONTRACT_DOCUMENT',
} as const;
export type ACCOUNT_REGISTER_DOCUMENT_TYPE =
  (typeof ACCOUNT_REGISTER_DOCUMENT_TYPE)[keyof typeof ACCOUNT_REGISTER_DOCUMENT_TYPE];
export enum ACCOUNT_REGISTER_ACCOUNT_DOCUMENT_TYPE {
  SOCIAL_CONTRACT = ACCOUNT_REGISTER_DOCUMENT_TYPE.SOCIAL_CONTRACT,
  ADDRESS_PROOF = ACCOUNT_REGISTER_DOCUMENT_TYPE.ADDRESS_PROOF,
  ATA = ACCOUNT_REGISTER_DOCUMENT_TYPE.ATA,
  BYLAWS = ACCOUNT_REGISTER_DOCUMENT_TYPE.BYLAWS,
}
export const getAccountRegisterDocumentI18n = (
  t: TFunction,
): Record<string, string> => ({
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.PICTURE]: t('Picture'),
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.CNH]: t('CNH'),
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.CNH_FRONT]: t('CNH Front'),
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.CNH_BACK]: t('CNH Back'),
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.IDENTITY_FRONT]: t('Identity Front'),
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.IDENTITY_BACK]: t('Identity Back'),
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.SOCIAL_CONTRACT]: t('Social Contract'),
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.ADDRESS_PROOF]: t('Proof of Address'),
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.ATA]: t('Ata'),
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.BYLAWS]: t('Bylaws'),
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.BILLING_PROOF]: t('Proof of Billing'),
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.SIGNATURE_DOCUMENT]: t('Terms of Use'),
  [ACCOUNT_REGISTER_DOCUMENT_TYPE.CONTRACT_DOCUMENT]: t('Contract Document'),
});

export const ACCOUNT_REGISTER_DOCUMENT_SIGNATURE_TYPE = {
  TEMPLATE: 'TEMPLATE',
  SIGNED: 'SIGNED',
} as const;

export const ACCOUNT_REGISTER_SIGNATURE_TYPE = {
  TERMS: 'TERMS',
  CONTRACT: 'CONTRACT',
} as const;
