import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { useTheme, type SxProps } from '@mui/material/styles';
import type { ReactNode } from 'react';

import { SwipeableModalTransition } from './SwipeableModalTransition.tsx';
import BoxFlex from '../mui/BoxFlex.tsx';

type SwipeableModalProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  children?: ReactNode;
  actions?: ReactNode;
  maxHeight?: string;
  width?: string;
  contentProps?: {
    sx?: SxProps;
  };
};

export const SwipeableModal = ({
  open,
  title,
  onClose,
  children,
  actions,
  width,
  maxHeight = '400px',
  contentProps,
}: SwipeableModalProps): ReactNode => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      sx={{
        '@media (max-width: 600px)': {
          zIndex: theme.zIndex.mobileStepper + contentProps?.sx.zIndex,
        },
      }}
    >
      <SwipeableModalTransition in={open}>
        <Paper
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            right: 0,
            boxShadow: theme.shadows[2],
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            height: 'fit-content',
            '@media (min-width: 600px)': {
              width,
              maxWidth: 700,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },
            '@media (max-width: 600px)': {
              marginBottom: 12,
            },
          }}
        >
          <DialogTitle id='dialog-title'>{title}</DialogTitle>
          <BoxFlex sx={{ maxHeight, overflowY: 'auto' }}>
            <DialogContent sx={contentProps?.sx} dividers>
              {children}
            </DialogContent>
          </BoxFlex>
          {actions && <DialogActions>{actions}</DialogActions>}
        </Paper>
      </SwipeableModalTransition>
    </Modal>
  );
};
