import type { TFunction } from 'i18next';

export const ACCOUNT_REGISTER_STATUS = {
  PENDING: 'PENDING', // user needs to validate all company and partners data
  IN_REVIEW: 'IN_REVIEW', // account register is ready to be reviewed by woovi team
  CREATING: 'CREATING', // creating account
  APPROVED: 'APPROVED', // approved account
  DELETED: 'DELETED', // deleted account

  REJECTED: 'REJECTED', // rejected account
  REJECTED_KYC: 'REJECTED_KYC', // rejected by our own KYC

  REJECTED_COMMERCIAL_INTEREST: 'REJECTED_COMMERCIAL_INTEREST', // sem interesse comercial
  REJECTED_RISK_PROFILE: 'REJECTED_RISK_PROFILE', // perfil de risco
  REJECTED_IRS_PENDING: 'REJECTED_IRS_PENDING', // pendencias receita federal
};

export const getAccountRegisterStatusI18n = (t: TFunction) => ({
  [ACCOUNT_REGISTER_STATUS.CREATING]: t('Creating in Provider'),
  [ACCOUNT_REGISTER_STATUS.IN_REVIEW]: t('Under Analysis'),
  [ACCOUNT_REGISTER_STATUS.PENDING]: t('Pending Data'),
  [ACCOUNT_REGISTER_STATUS.REJECTED]: t('Rejected'),
  [ACCOUNT_REGISTER_STATUS.REJECTED_KYC]: t('Rejected by KYC'),
  [ACCOUNT_REGISTER_STATUS.REJECTED_COMMERCIAL_INTEREST]: t(
    'Rejected by Commercial Interest',
  ),
  [ACCOUNT_REGISTER_STATUS.REJECTED_IRS_PENDING]: t(
    'Rejected by IRS Pendencies',
  ),
  [ACCOUNT_REGISTER_STATUS.REJECTED_RISK_PROFILE]: t(
    'Rejected by Risky Profile',
  ),
  [ACCOUNT_REGISTER_STATUS.APPROVED]: t('Approved'),
  [ACCOUNT_REGISTER_STATUS.DELETED]: t('Deleted'),
});
