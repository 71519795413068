import type { Theme } from '@mui/material';
import { useField } from 'formik';
import type { PhoneInputProps } from 'material-ui-phone-number';
import MuiPhoneInput from 'material-ui-phone-number';
import type { ReactNode } from 'react';

export type PhoneInputFormik = {
  name: string;
  placeholder?: string;
} & PhoneInputProps;

const PhoneInputFormik = (props: PhoneInputFormik): ReactNode => {
  const { name, ...rest } = props;

  const [field, meta, helpers] = useField(name);

  const inputProps = {
    'data-testid': name,
  };

  const getErrorProps = ():
    | { error: true; helperText: string }
    | Record<string, string | boolean> => {
    if (typeof meta?.error === 'string' && meta.touched) {
      return {
        error: !!meta?.error,
        helperText: meta?.error,
      };
    }

    return {};
  };

  const errorProps = getErrorProps();

  const getSx = (theme: Theme): Theme => {
    if (rest.sx instanceof Function) {
      return rest.sx(theme);
    }

    return rest.sx;
  };

  return (
    <MuiPhoneInput
      defaultCountry={'br'}
      countryCodeEditable={false}
      value={field.value}
      onChange={(phone) => helpers.setValue(phone)}
      onBlur={() => helpers.setTouched(true)}
      inputProps={inputProps}
      variant='outlined'
      {...errorProps}
      {...rest}
      sx={(theme) => ({
        '.MuiPhoneNumber-flagButton': { width: 30 },
        '.margin': { width: '100%' },
        ...getSx(theme),
      })}
    />
  );
};

export default PhoneInputFormik;
