import { ChevronRight } from '@mui/icons-material';
import { Typography } from '@mui/material';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useFragment, graphql } from 'react-relay';
import { useHistory } from 'react-router-dom';

import { ADMIN_ROLE, ROLES } from '@woovi/roles';
import {
  ACCOUNT_REGISTER_DOCUMENT_TYPE,
  ACCOUNT_REGISTER_STATUS,
} from '@woovi/shared';
import { ActionButton } from '@woovi/ui';

import type { AccountRegisterRequestedDocumentsHeaderBanner_me$key } from './__generated__/AccountRegisterRequestedDocumentsHeaderBanner_me.graphql.ts';
import { Warning } from './HeaderBanner.tsx';
import { routeToMain } from '../../router/utils/index.tsx';
import FeatureFlag from '../common/v2/featureFlag/FeatureFlag.tsx';

type AccountRegisterRequestedDocumentsHeaderBannerProps = {
  me: AccountRegisterRequestedDocumentsHeaderBanner_me$key;
};

const AccountRegisterRequestedDocumentsHeaderBanner = (
  props: AccountRegisterRequestedDocumentsHeaderBannerProps,
): ReactNode => {
  const { t } = useTranslation();
  const history = useHistory();

  const me = useFragment<AccountRegisterRequestedDocumentsHeaderBanner_me$key>(
    graphql`
      fragment AccountRegisterRequestedDocumentsHeaderBanner_me on User
      @argumentDefinitions(first: { type: "Int", defaultValue: 50 }) {
        ...FeatureFlag_user
        company {
          accountRegisters(first: $first)
            @connection(
              key: "AccountRegisterRequestedDocumentsHeaderBanner_accountRegisters"
              filters: []
            ) {
            edges {
              node {
                shouldUpdateDocuments
                status
                companyBankAccount {
                  id
                }
                representatives {
                  requestDocuments
                  shouldUpdateDocuments
                }
              }
            }
          }
        }
      }
    `,
    props.me,
  );

  const hasOnlyContractDocument = me.company?.accountRegisters.edges.some(
    (edge) =>
      edge?.node?.representatives?.some(
        (representative) =>
          representative?.requestDocuments?.length === 1 &&
          representative?.requestDocuments?.includes(
            ACCOUNT_REGISTER_DOCUMENT_TYPE.CONTRACT_DOCUMENT,
          ),
      ),
  );

  if (hasOnlyContractDocument) {
    return null;
  }

  const accountWithRequestedDocuments = me.company?.accountRegisters.edges.find(
    ({ node }) => {
      if (node.shouldUpdateDocuments) {
        return true;
      }

      if (node.status === ACCOUNT_REGISTER_STATUS.DELETED) {
        return false;
      }

      const representativeWithRequestedDocuments = node.representatives?.find(
        ({ shouldUpdateDocuments }) => shouldUpdateDocuments,
      );

      if (representativeWithRequestedDocuments) {
        return true;
      }

      return false;
    },
  );

  if (!accountWithRequestedDocuments?.node) {
    return null;
  }

  const handleClick = (): void => {
    if (!accountWithRequestedDocuments?.node?.companyBankAccount?.id) {
      return;
    }

    return history.push(
      routeToMain('companyBank.details.edit', {
        id: accountWithRequestedDocuments.node.companyBankAccount.id,
      }),
    );
  };

  return (
    <Warning>
      <Typography>
        <b>{t('Registration update.') + ' '}</b>
        {t('Update your data to keep your account always safe and efficient')}
      </Typography>
      <FeatureFlag user={me} roles={[ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL]}>
        <ActionButton
          variant='contained'
          color='tertiary'
          endIcon={<ChevronRight />}
          onClick={handleClick}
          sx={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}
        >
          {t('Update data')}
        </ActionButton>
      </FeatureFlag>
    </Warning>
  );
};

export default AccountRegisterRequestedDocumentsHeaderBanner;
