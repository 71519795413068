import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/clickpix';

export const clickpixRoutes: RouteType[] = [
  {
    name: 'clickpix',
    path: `${PATH}/tab`,
    labelHeader: ({ t }) => t('ClickPix'),
    label: ({ t }) => t('ClickPix'),
    component: loadable(
      () => import('../../components/clickpix/ClickPixHeader.tsx'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.CLICK_PIX],
    routes: [
      {
        name: 'clickpix.tutorial',
        path: `${PATH}/tab/tutorial`,
        labelHeader: ({ t }) => t('Tutorial'),
        label: ({ t }) => t('Tutorial'),
        component: loadable(
          () =>
            import('../../components/clickpix/tutorial/ClickPixTutorial.tsx'),
        ),
      },
    ],
  },
  {
    name: 'clickpix.create',
    path: `${PATH}/create`,
    labelHeader: ({ t }) => t('Create ClickPix Button'),
    label: ({ t }) => t('Create ClickPix Button'),
    component: loadable(
      () => import('../../components/clickpix/create/ClickPixButtonCreate.tsx'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.CLICK_PIX],
  },
  {
    name: 'clickpix.details',
    path: `${PATH}/detail/:id`,
    labelHeader: ({ t }) => t('ClickPix Detail'),
    label: ({ t }) => t('Detail ClickPix'),
    component: loadable(
      () =>
        import('../../components/clickpix/detail/data/ClickPixButtonRoot.tsx'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.CLICK_PIX],
    routes: [
      {
        name: 'clickpix.details.data',
        path: `${PATH}/detail/:id/data`,
        labelHeader: ({ t }) => t('Data'),
        label: ({ t }) => t('Data'),
        component: loadable(
          () =>
            import(
              '../../components/clickpix/detail/data/ClickPixButtonData.tsx'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.CORE.APPLICATION,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.API_WEBHOOK,
        ],
        requiredFeatures: [MODULES.CORE, MODULES.OPEN_PIX],
      },
    ],
  },
];
